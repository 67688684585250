body{
  text-align: left;
}
.App {
  text-align: center;
}

.App-logo {
  height: 20vmin;
  pointer-events: none;
}

.layout{
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 1fr;
}

/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #fe195e;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

 .button {
  background: #fe195e;
  border-color: #fe195e;
  color: #fff;
  padding: 12px 15px;
  cursor: pointer;
  transition: color 1s;
  margin: 15px;
  width:115px;
  height: 29px;
  font-size: 15px;
  padding: 2px 10px
} 

table {
  width: 100%;
  border: 1px solid #1111;
}

th, td {
  font-size: 25px;
  width: 25%;
  text-align: center;
  vertical-align: top;
  border: 1px solid rgb(0, 0, 0);
}

.rich-text{
  margin: 15px;
  width: 250px;
  height: 30px;
  font-size: 15px;
  padding: 5px 15px;
}

.select{
  margin: 15px;
  width: 250px;
  height: 30px;
  font-size: 15px;
  padding: 5px 15px;
}

h2{
  text-align: left;
  padding: 2%;
}

.header-table {
  background: #fe195e;
  border-color: #fe195e;
  color: #fff;
  padding: 12px 15px;
  transition: color 1s;
  margin: 15px;
  width:115px;
  height: 29px;
  font-size: 15px;
  padding: 2px 10px
} 

