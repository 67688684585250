@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@400;600&display=swap');


$light: #ffffff;
$gray: #c4ccde;

.flex {
    display: flex;
}

* {
    margin: 0;
    padding: 0;
}

.navbar {
    background-color: $light;
    padding: 1rem 2rem;
    border-bottom: 1px solid #fe195e;
}

body {
    font-family: 'Rubik', sans-serif;
}

.sidebar{
    width: 14rem;
    height: calc(100vh - 52px);
    border-right: 1px solid #fe195e;
    
    ul {
        list-style: none;
        padding: 1rem 2rem;
        li {
            margin-bottom: 1rem;
        }
        a {
            text-decoration: none;
        }

    }
 .textcolor {
    color: #fe195e;
 }   
}

.contenido {
    position: absolute;
    top: 75px;
    left: 300px;
    /*width: 67%;
    right: 200px;
    margin-top: 5%;*/
    
}

.logo { 
    display: block;
    margin: 0 auto;
    width: 120px;
    text-align: center;
  }

  h2 {
    text-align: left;
  }

  .select{
    text-align: center;
  }
  .select2{
    text-align: center;
  }
  
  #btnInicio {
    cursor: pointer;
  }


 